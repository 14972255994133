.container {
  width: 100vw;
  height: 70vh;
  // background: #6c7a89;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.box {
  width: 70%;
  height: auto;
}
