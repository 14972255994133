.labelTextField {
  text-align: left;
  font: Bold 18px/27px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.inputTextField {
  display: flex;
  max-width: 100%;
  min-width: 100%;
  font: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
  padding: 16px 16px;
  border-radius: 8px;
  border: 2px solid #e3e3e3;
  transition: 0.3s;
  &:focus {
    border: 2px solid #808080;
  }
}

.labelInputDocuments {
  text-align: left;
  font: Bold 16px/20px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.inputDocumentos {
  display: flex;
  max-width: 100%;
  min-width: 100%;
  font: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
  padding: 8px 8px;
  border-radius: 8px;
  border: 2px solid #e3e3e3;
  transition: 0.3s;
  &:focus {
    border: 2px solid #808080;
    outline: 0;
  }
}

.inputError {
  border: 2px solid #c63737;
  color: #c63737;
}

.labelError {
  color: #c63737;
}
.labelCheckbox {
  cursor: pointer;
}
// .labelCheckbox {
//     font: 16px/24px Open Sans;
//     text-align: left;
//     letter-spacing: 0px;
//     color: #4c5153;
// }

.divField {
  // margin: 10%;
  max-width: 100%;
}

// .divLabelCheckbox {
//     margin: 5%;
// }

.ql-font-verdana {
  font-family: 'Verdana';
}

.ql-container {
  display: grid !important;
  box-sizing: border-box;
  font-family: Verdana !important;
  font-size: 12px !important;
  height: 100%;
  margin: 0px;
  position: relative;
}

.inputFind {
  border: 2px solid #e3e3e3;
  padding: 10px, 10px, 10px, 10px;
  border-radius: 8px;

  .input-group-text {
    background-color: #fff !important;
  }

  input {
    border: 0;
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
    padding: 5px;
  }

  select {
    border: 0;
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
    padding: 5px;
  }

  button {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
    padding: 5px;
  }

  button:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }
}

@import url('https://fonts.googleapis.com/css?family=Open + Sans');
.labelCheckbox {
  text-align: left;
  font-size: 15px;
  // font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans';
  line-height: 24px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
