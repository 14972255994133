.borda_superior {
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 30%;
  background: #e3e3e3 0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute;
}

.login-container {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
}

.container-login {
  margin-top: 5rem;

  @media screen and (max-width: 500px) {
    margin-top: 2rem;
  }
}

.card-login {
  padding: 3.8rem 2.5rem;
  border-radius: 10px !important;
  border-color: #e4e4e4;

  @media screen and (max-width: 500px) {
    padding: 2.5rem 2rem;
  }

  @media screen and (min-height: 731px) {
    margin-top: 10%;
  }
}

.logo-jus {
  max-width: 50%;
  @media screen and (max-width: 500px) {
    max-width: 60%;
  }
}

// temporário
.logo-rodape {
  max-width: 20%;
  @media screen and (max-width: 575px) {
    max-width: 50%;
  }
}
