@import url(https://fonts.googleapis.com/css?family=Open + Sans);
@import url(https://fonts.googleapis.com/css?family=Open + Sans);
.borda_superior{top:0px;left:0px;width:100%;min-height:30%;background:#e3e3e3 0% 0% no-repeat padding-box;opacity:1;position:absolute}.login-container{height:100vh;width:100vw;background-color:#fff}.container-login{margin-top:5rem}@media screen and (max-width: 500px){.container-login{margin-top:2rem}}.card-login{padding:3.8rem 2.5rem;border-radius:10px !important;border-color:#e4e4e4}@media screen and (max-width: 500px){.card-login{padding:2.5rem 2rem}}@media screen and (min-height: 731px){.card-login{margin-top:10%}}.logo-jus{max-width:50%}@media screen and (max-width: 500px){.logo-jus{max-width:60%}}.logo-rodape{max-width:20%}@media screen and (max-width: 575px){.logo-rodape{max-width:50%}}

.labelTextField{text-align:left;font:Bold 18px/27px Open Sans;letter-spacing:0px;color:#000000;opacity:1}.inputTextField{display:flex;max-width:100%;min-width:100%;font:Open Sans;font-size:16px;font-weight:400;line-height:24px;margin-bottom:0;padding:16px 16px;border-radius:8px;border:2px solid #e3e3e3;transition:0.3s}.inputTextField:focus{border:2px solid #808080}.labelInputDocuments{text-align:left;font:Bold 16px/20px Open Sans;letter-spacing:0px;color:#000000;opacity:1}.inputDocumentos{display:flex;max-width:100%;min-width:100%;font:Open Sans;font-size:16px;font-weight:400;line-height:18px;margin-bottom:0;padding:8px 8px;border-radius:8px;border:2px solid #e3e3e3;transition:0.3s}.inputDocumentos:focus{border:2px solid #808080;outline:0}.inputError{border:2px solid #c63737;color:#c63737}.labelError{color:#c63737}.labelCheckbox{cursor:pointer}.divField{max-width:100%}.ql-font-verdana{font-family:'Verdana'}.ql-container{display:grid !important;box-sizing:border-box;font-family:Verdana !important;font-size:12px !important;height:100%;margin:0px;position:relative}.inputFind{border:2px solid #e3e3e3;padding:10px, 10px, 10px, 10px;border-radius:8px}.inputFind .input-group-text{background-color:#fff !important}.inputFind input{border:0;box-shadow:0 0 0 0;border:0 none;outline:0;padding:5px}.inputFind select{border:0;box-shadow:0 0 0 0;border:0 none;outline:0;padding:5px}.inputFind button{box-shadow:0 0 0 0;border:0 none;outline:0;padding:5px}.inputFind button:focus{box-shadow:0 0 0 0;border:0 none;outline:0}.labelCheckbox{text-align:left;font-size:15px;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans';line-height:24px;letter-spacing:0px;color:#000000;opacity:1}

.container{width:100vw;height:70vh;display:flex;flex-direction:row;justify-content:center;align-items:center}.box{width:70%;height:auto}

.labelTextField{text-align:left;font-size:16px;font-weight:bold;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans';line-height:24px;color:#000000;opacity:1}.labelCorpoTexto{text-align:left;font-size:16px;font-weight:400;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans';line-height:24px;color:#000000;opacity:1}

.lds-ellipsis{display:inline-block;position:relative;width:80px;height:80px}.lds-ellipsis div{position:absolute;top:33px;width:13px;height:13px;border-radius:50%;background:#181616;-webkit-animation-timing-function:cubic-bezier(0, 1, 1, 0);animation-timing-function:cubic-bezier(0, 1, 1, 0)}.lds-ellipsis div:nth-child(1){left:8px;-webkit-animation:lds-ellipsis1 0.6s infinite;animation:lds-ellipsis1 0.6s infinite}.lds-ellipsis div:nth-child(2){left:8px;-webkit-animation:lds-ellipsis2 0.6s infinite;animation:lds-ellipsis2 0.6s infinite}.lds-ellipsis div:nth-child(3){left:32px;-webkit-animation:lds-ellipsis2 0.6s infinite;animation:lds-ellipsis2 0.6s infinite}.lds-ellipsis div:nth-child(4){left:56px;-webkit-animation:lds-ellipsis3 0.6s infinite;animation:lds-ellipsis3 0.6s infinite}@-webkit-keyframes lds-ellipsis1{0%{-webkit-transform:scale(0);transform:scale(0)}100%{-webkit-transform:scale(1);transform:scale(1)}}@keyframes lds-ellipsis1{0%{-webkit-transform:scale(0);transform:scale(0)}100%{-webkit-transform:scale(1);transform:scale(1)}}@-webkit-keyframes lds-ellipsis3{0%{-webkit-transform:scale(1);transform:scale(1)}100%{-webkit-transform:scale(0);transform:scale(0)}}@keyframes lds-ellipsis3{0%{-webkit-transform:scale(1);transform:scale(1)}100%{-webkit-transform:scale(0);transform:scale(0)}}@-webkit-keyframes lds-ellipsis2{0%{-webkit-transform:translate(0, 0);transform:translate(0, 0)}100%{-webkit-transform:translate(24px, 0);transform:translate(24px, 0)}}@keyframes lds-ellipsis2{0%{-webkit-transform:translate(0, 0);transform:translate(0, 0)}100%{-webkit-transform:translate(24px, 0);transform:translate(24px, 0)}}

