@import url('https://fonts.googleapis.com/css?family=Open + Sans');
.labelTextField {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans';
  line-height: 24px;
  color: #000000;
  opacity: 1;
}

.labelCorpoTexto {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans';
  line-height: 24px;
  color: #000000;
  opacity: 1;
}
